.ai-results {
    background: linear-gradient(20deg, #e3e3ff 80%, #e3e3ff 100%);
    color: #333 !important;
    border: 1px solid #333 !important;
    border-radius: 15px;
    backdrop-filter: blur(100px);
    margin-bottom: 30px !important;
}

.ai-disclaimer {
    font-size: 0.9rem !important;
    font-weight: 300;
}

#inputAI {
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding: 30px;
}
  