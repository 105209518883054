.home-pros-section h3 {
  font-size: 1.55rem !important;
  font-weight: 500;
  color: #5a39fd;
}

.home-pros-section p {
  font-size: 1.20rem !important;
  font-weight: 300;
}

.home-pros-section {
  position: relative;
  overflow: hidden;
  z-index: -1;
  background: linear-gradient(70deg, #efeffd 100%, #ffffff 100%);
}

.pros-column {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 10;
}

.pros-column::before {
  content: '';
  position: absolute;
  top: 90%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background-image: url('../../images/wavy.png');
  background-size: contain;
}