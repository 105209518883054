/* HomeRegister.css */

.home-register-section p {
  font-size: 1.2rem !important;
  font-weight: 300;
}

.home-register-section {
  position: relative;
  background-color: #f8f9fa;
  height: 60vh;
  overflow: hidden;
  background-image: url(../../images/grid2.png);
  background-size: 70%;
  background-position: center;
}

.home-register-background {
  position: absolute;
  top: 80%;
  width: 100vw;
  height: 50vh;
  background: linear-gradient(70deg, #efeffd 100%, #ffffff 100%);
  clip-path: ellipse(80% 50% at 72% 18%);
  filter: blur(120px);
  transform: translate(-10%, -50%);
  transition: filter 0.3s ease-in-out;
}

.home-register-section p {
  color: #363848 !important;
}

.btn-outline-register {
  color: #5a39fd;
  border: 2px solid #5a39fd !important;
  background-color: transparent !important;
  transition: all 0.3s ease;
  border-radius: 20px;
  color: var(--color-secondary) !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.btn-outline-register:hover,
.btn-outline-register:focus {
  color: #fff;
  border-color: var(--color-secondary);
  box-shadow: 0 0 10px rgba(90, 47, 253, 0.5);
  background-color: transparent !important;
}

.btn-outline-register:active {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  box-shadow: none;
}

@media (min-width: 359px) and (max-width: 431px) {
  .home-register-section {
    padding-top: 5vh;
  }
}

/* Mobile view for Samsung Galaxy S8+ */
/* Mobile view for iPhone SE */
@media (min-width: 359px) and (max-width: 380px) {
  .home-register-section {
    height: 75vh;
  }
}

@media (min-width: 389px) and (max-width: 395px) {
  .home-register-section {
    height: 60vh;
  }
}

@media (min-width: 395px) and (max-width: 431px) {
  .home-register-section {
    height: 55vh;
  }
}
