.hero-background {
  position: absolute;
  inset: 0;
  width: 180vw;
  height: 160vh;
}

.hero-background::before {
  content: '';
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(70deg, #efeffd 100%, #ffffff 100%);
  clip-path: ellipse(53% 30% at 42% 18%);
}

.hero-section {
  position: relative;
  overflow: hidden;
  height: 80vh;
  display: flex;
  align-items: center;
}

.hero-description {
  font-size: 1.25rem !important;
  font-weight: 300;
  line-height: 2.25rem;
}

.hero-image {
  background-image: url("../../images/home_hero.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 420px;
}

/* Mobile view for ALL devices */
@media (min-width: 359px) and (max-width: 431px) {
  .hero-section h1 {
    font-size: 8.75vw !important;
  }

  .hero-image {
    margin-left: calc(-50vw + 62%);
  }
}

/* Mobile view for Samsung Galaxy S8+ */
/* Mobile view for iPhone SE */
@media (min-width: 359px) and (max-width: 380px) {
  .hero-background::before {
    clip-path: ellipse(55% 22% at 32% 18%);
  }
}

/* Mobile view for Samsung Galaxy S8+ */
@media (min-width: 359px) and (max-width: 369px) {
  .hero-section {
    height: 65vh;
  }

  .hero-image {
    min-height: 20vh;
  }
}

/* Mobile view for iPhone SE */
@media (min-width: 370px) and (max-width: 380px) {
  .hero-section {
    height: 70vh;
  }

  .hero-image {
    min-height: 25vh;
  }
}

/* Mobile view for Samsung Galaxy S20 Ultra */
/* Mobile view for iPhone XR */
/* Mobile view for iPhone 12, 13, 14, 15, 16 Pro */
/* Mobile view for iPhone 12, 13, 14, 15, 16 Pro Max */
@media (min-width: 389px) and (max-width: 431px) {
  .hero-section {
    height: 55vh;
  }

  .hero-image {
    min-height: 20vh;
  }

  .hero-background::before {
    clip-path: ellipse(55% 22% at 32% 12%);
  }
}