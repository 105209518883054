/* LawyerIntro styles */
.ld-intro {
  background: 
    url(../../images/grid4.png) repeat center,
    linear-gradient(10deg, #efeffd 100%, #ffffff 100%);
  background-size: contain, cover;
  height: 35vh;
  margin-top: 2.5vh;
  position: relative;
  border-bottom-left-radius: 70% 70px;
  border-bottom-right-radius: 70% 70px;
}

.ld-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  z-index: 1;
  position: absolute;
  left: 8%;
  transform: translateY(30%);
  z-index: 1;
}

.ld-image-profile {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
  filter: grayscale(100%);
}

.ld-grade {
  font-family: var(--primary-font) !important;
  font-size: 0.8rem !important;
  font-weight: var(--font-weight-regular) !important;
  position: absolute;
  bottom: 35%;
  right: 8%;
}

.ld-social-icons {
  position: absolute;
  right: 8%;
  bottom: 20%;
  display: flex;
  gap: 12px;
}

.social-icon {
  color: #333;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--color-secondary);
}

.ld-mediation {
  font-family: var(--secondary-font) !important;
  font-size: 0.75rem !important;
  font-weight: var(--font-weight-medium);
  position: absolute;
  right: 45%;
  bottom: 15%;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  color: var(--color-primary);
  padding: 6px 18px;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {

  .ld-intro-mb {
    background: linear-gradient(10deg, #efeffd 100%, #ffffff 100%);
    margin-top: 5vh;
    position: relative;
    border-bottom-left-radius: 70% 70px;
    border-bottom-right-radius: 70% 70px;
    height: 35vh !important;
    margin-bottom: 10vh;
  }

  .ld-img-container-mb {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .ld-img-mb {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 1.5px solid #ffffff;
    filter: grayscale(100%);
  }

  .lawyer-social-icons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    font-size: 0.65rem !important;
  }

  .ld-grade-mb {
    font-size: 0.85rem !important;
  }

  .ld-medi-mb {
    position: absolute;
    left: 49%;
    transform: translateX(-50%);
    top: 8%;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    color: var(--color-primary);
    padding: 5px 8px;
    border-radius: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    font-size: 0.85rem !important;
    display: flex;
    justify-content: center;
  }

}
