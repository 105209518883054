/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap');

/* CSS Variables */
:root {
  --primary-font: "Manrope", sans-serif;
  --secondary-font: "IBM Plex Mono", monospace;
  --mono-font: "IBM Plex Mono", monospace;
  --brand-font: "Poppins", sans-serif;

  --color-primary: #363848;
  --color-secondary: #5a39fd;
  --color-carmine: #DC143C;
  --color-background: #f8f9fd;
  --color-white: #f8f9fd;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --font-weight-strong: 650;
}

/* Global Styles */
html, body {
  overflow-x: hidden;
}

body {
  font-family: var(--primary-font);
  background-color: var(--color-background);
}

h1 {
  font-family: var(--primary-font);
  font-weight: var(--font-weight-strong);
  font-size: 3.2rem;
  letter-spacing: -.05em;
  color: var(--color-primary);
}

h2 {
  font-family: var(--primary-font);
  font-weight: var(--font-weight-strong);
  font-size: 0.95rem;
  color: var(--color-primary);
}

h3 {
  font-family: var(--primary-font) !important;
  font-weight: var(--font-weight-strong);
  letter-spacing: -.03em !important;
  color: var(--color-primary);
}

h5 {
  font-family: var(--primary-font) !important;
  font-weight: var(--font-weight-light);
  font-size: 1.15rem;
}

.h5 {
  font-weight: var(--font-weight-light);
}

p {
  font-family: var(--primary-font);
  font-size: 1rem !important;
  color: var(--color-primary);
}

a {
  color: var(--color-primary) !important;
}

a:hover {
  color: var(--color-primary) !important;
}

label {
  font-family: var(--primary-font) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: var(--color-primary);
}

small {
  font-size: 0.7em;
}

strong {
  font-weight: var(--font-weight-medium) !important;
}

footer {
  font-family: var(--primary-font);
  font-size: 0.85rem !important;
}

/* Navbar Styles */
.navbar {
  font-family: var(--primary-font);
  font-size: 1.25rem;
  padding-top: 1.5rem;
}

.navbar-transparent {
  background-color: transparent;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.navbar-blur {
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.navbar-toggler {
  border: none;
  color: var(--color-primary) !important;
  font-size: 1rem !important;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  border: none !important;
  color: var(--color-primary) !important;
}

.nav-link {
  font-size: 0.98rem;
  font-weight: 500;
  position: relative;
  color: var(--color-primary);
  text-decoration: none;
}

.nav-link:hover {
  color: var(--color-secondary) !important;
}

.nav-btn {
  font-size: 0.90rem !important;
  font-weight: 400 !important;
}

.main-content {
  transition: filter 0.3s ease;
}

.blur-background {
  filter: blur(5px);
}

.cookie-consent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  backdrop-filter: blur(2px);
  z-index: 1001;
}

.cookie-consent p {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.cookie-consent button {
  margin-right: 20px;
  padding: 8px 16px;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  color: #007bff;
  font-size: 0.9rem;
}

.cookie-consent button:last-child {
  margin-right: 0;
}

.cookie-consent button:hover {
  background-color: #007bff;
  color: #ffffff;
}

@media (max-width: 600px) {
  .cookie-consent {
    width: calc(100% - 40px);
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: 8px;
  }

  .cookie-consent p {
    font-size: 0.85rem;
  }

  .cookie-consent button {
    width: calc(50% - 10px);
    margin-top: 10px;
    margin-right: 5px;
    font-size: 0.80rem;
  }

  .cookie-consent button:last-child {
    margin-right: 0;
  }
}

.form-control {
  font-family: var(--primary-font) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  background-color: transparent;
  border: 1px solid #333 !important;
}

.form-control:focus {
  background-color: transparent;
  border-color: #333;
  box-shadow: none;
}

.css-1hkihtm-MuiPaper-root-MuiAutocomplete-paper {
  font-family: var(--primary-font) !important;
  letter-spacing: -.01em !important;
  color: var(--color-primary);
}

.scrolled-heading {
  transition: margin-bottom 0.5s ease;
  margin-bottom: 10px;
}

.scrolled-text {
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.intro-p {
  font-size: 0.80rem;
  font-family: var(--primary-font);
  font-weight: 300;
}

@media (max-width: 768px) {
  .transition-container {
    padding: 10px 15px;
  }

  .scrolled-heading,
  .scrolled-text {
    margin-bottom: 0;
  }
}

.min-vh-100 {
  min-height: calc(100vh - 50px) !important;
}

.text-sm {
  font-size: 0.65rem;
  padding: 5px;
}

.card-association {
  border: 1px solid #a3a3a3 !important;
  border-radius: 15px !important;
}

.btn-outline-primary {
  position: relative;
  overflow: hidden;
  border: solid 1.5px var(--color-secondary);
  color: var(--color-secondary) !important;
  background-color: transparent;
  transition: box-shadow 0.2s ease, color 0.3s ease;
  border-radius: 20px;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: var(--color-secondary) !important;
  border: solid 1.5px var(--color-secondary);
  background-color: transparent;
  box-shadow: 0 0 4px var(--color-secondary);
  outline: none;
}

.custom-underline {
  position: relative;
  display: inline-block;
}

.custom-underline::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0.2em;
  width: 100%;
  height: 0.2em;
  background-color: var(--color-secondary) !important;
  z-index: -10;
  transform: skewX(180deg);
}

.text-primary {
  color: var(--color-secondary) !important;
}

.bg-primary {
  background-color: var(--color-secondary) !important;
}

.bg-grey {
  background-color: rgb(90, 57, 253, 0.05) !important;
  color: var(--color-primary);
}

.icon-wrapper {
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.icon-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.icon-link:hover {
  text-decoration: none;
}

@media (max-width: 767px) {
  .image-container {
    background-size: contain;
    min-height: 250px !important;
  }

  .bg-grey {
    padding-left: 2rem;
  }
  
}

.icon-wrapper {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.icon-wrapper:hover {
  transform: scale(1.15);
}

.icon-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.icon-link:hover {
  color: var(--color-secondary) !important;
}


.btn-outline-uplawyer {
  color: var(--color-secondary);
  border: 1.5px solid var(--color-secondary);
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 18px;
  color: var(--color-secondary) !important;
}

.btn-outline-uplawyer:hover,
.btn-outline-uplawyer:focus {
  color: #fff;
  border-color: var(--color-secondary);
  box-shadow: 0 0 10px rgba(90, 47, 253, 0.5);
  color: var(--color-secondary) !important;
}

.btn-outline-uplawyer:active {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  box-shadow: none;
}

.page-wrapper {
  position: relative;
  background: linear-gradient(70deg, #efeffd 0%, #ffffff 80%);
  overflow: hidden;
}

.bottom-curve {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.filter-h5 {
  font-family: var(--primary-font) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: var(--color-primary) !important;
}
