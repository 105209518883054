.blog-card {
  border: none !important;
  background: linear-gradient(0deg, #efeffd 100%, #ffffff 100%);
  padding: 10px !important;
  border-radius: 1.5rem !important;
}

.blog-title {
  font-family: var(--primary-font);
  font-size: 1.5rem;
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
}

.blog-title:hover {
  color: var(--color-secondary);
}

.blog-meta {
  font-family: var(--primary-font) !important;
  font-size: 0.95rem !important;
  font-weight: var(--font-weight-regular);
}

.blog-excerpt {
  font-family: var(--primary-font);
  font-size: 1.1rem;
  font-weight: var(--font-weight-light);
  line-height: 2rem;
  text-align: justify;
}

.blog-read-more {
  font-family: var(--primary-font);
  font-size: 0.95rem;
  font-weight: var(--font-weight-medium);
  color: var(--color-secondary);
}

.blog-post {
  border: none !important;
  background-color: transparent !important;
}

.blog-post-title {
  font-size: 2.5rem !important;
  font-weight: var(--font-weight-medium) !important;
  line-height: 3.5rem !important;
  text-align: center !important;
}

.blog-post-body {
  font-family: var(--primary-font);
}

.blog-post-meta {
  font-size: 0.9rem !important;
  font-weight: var(--font-weight-regular) !important;
}

.blog-post-meta .separator {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .blog-post-meta {
    flex-direction: column;
    text-align: center;
  }

  .blog-post-meta span.separator {
    display: none;
  }

  .blog-post-meta div {
    margin-bottom: 5px;
  }
}