.p-contact {
  font-size: 1.2rem !important;
  font-weight: var(--font-weight-light);
}

.btn-outline-contact {
    border: 1.5px solid var(--color-secondary) !important;
    background-color: transparent !important;
    transition: all 0.3s ease;
    color: var(--color-secondary) !important;
    padding: 0.5rem 2rem !important;
}

.btn-outline-contact:hover,
.btn-outline-contact:focus {
    box-shadow: 0 0 10px rgba(90, 47, 253, 0.5);
}

.btn-outline-contact:active {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    box-shadow: none;
}

.form-select {
  font-family: var(--primary-font) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  background-color: transparent !important;
  border: 1px solid #333 !important;
}

.alert-success {
  background-color: #d8d0ff !important;
  border: 1.5px solid #5a39fd !important;
  color: #431dfc !important;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 15px 20px !important;
}

.alert-success .btn-close {
  font-size: 0.7rem !important;
}