.specialty-card {
    background-color: transparent !important; /* Remove the background */
    border: none !important; /* Remove the border */
    box-shadow: none !important; /* Remove any shadow */
  }
  
  .specialty-card-title a {
    color: #5a39fd !important; /* Set the title color */
  }

  .purple {
    color: #5a39fd !important;
  }

  .specialty-card {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .parallelogram {
    position: relative;
    overflow: hidden;
    width: 250px; /* Adjust width */
    height: 150px; /* Adjust height */
    margin: 0 auto 1rem; /* Centers and adds margin below */
  }
  
  .parallelogram-image {
    width: 80%;
    height: 80%;
    object-fit: fit;

  }
  
  .specialty-card-subtitle {
    font-weight: 200 !important;
    line-height: 25px !important;
  }

  .custom-search-bar {
    height: 60px; /* Adjust the height as needed */
    font-size: 16px; /* Adjust font size for better readability */
    padding: 20px !important; /* Adjust padding for better spacing */
  }

  @media (max-width: 600px) {

    .parallelogram {
        position: relative;
        overflow: hidden;
        width: 35vw; /* Adjust width */
        height: 10vh; /* Adjust height */
        margin: 0 auto 1rem; /* Centers and adds margin below */
    }
}