.blog-post-card {
    border: 1px solid #8b8b8b !important;
    border-radius: 25px !important;
    background-color: #efeffd !important;
}

.blog-post-lawyer-title {
    font-size: 1.2rem !important;
    font-weight: var(--font-weight-medium) !important;
    line-height: 1.85rem;
    color: #333 !important;
    text-decoration: none !important;
}