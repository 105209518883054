@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap');

.lawyer-profile-image {
  width: 120px;
  height: 120px;
}

.card-background {
  padding: 15px;
}

.ld-h2 {
  display: flex;
  align-items: center;
  font-size: 1.45rem;
  font-weight: 500;
}

.lang-flag {
  filter: grayscale(90%);
}

.blog-post-card-lawyer-card-grade-dikigoros_par_efetais {
  border: none !important;
  background: linear-gradient(0deg, #efeffd 100%, #ffffff 100%);
  padding: 10px !important;
  border-radius: 8px !important;
}

.blog-post-excerpt {
  font-size: 0.90rem !important;
  line-height: 1.75rem;
}

@media (max-width: 767px) {
  .heading-full-line {
    margin-top: 20px;
  }

  .contact-info-container {
    margin-top: 20px;
  }
}

.blur-background {
  filter: blur(5px);
  transition: filter 0.3s ease;
}

.btn-primary-contact {
  color: #333 !important;
  border: 1px solid #333 !important;
  font-size: 14px !important;
  background-color: transparent !important;
  transition: all 0.3s ease;
  border-radius: 25px !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.btn-primary-contact:hover,
.btn-primary-contact:focus {
  color: #fff;
  border-color: var(--color-secondary);
  box-shadow: 0 0 2px rgba(32, 32, 33, 0.5);
  background-color: transparent !important;
}

.btn-primary-contact:active {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  box-shadow: none;
}

.modal-content {
  border: 1.5px solid #333 !important;
  border-radius: 25px !important;
  padding: 20px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.modal-header {
  border-bottom: 1.5px solid #333 !important;
}

.modal-title {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  color: #333;
}

.modal-body {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1rem;
}

.modal-footer .btn {
  border-radius: 12px;
}

.modal .form-control {
  border: 1px solid #333;
  border-radius: 12px;
  padding: 12px;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.85rem !important;
  color: #333;
}

.modal .form-label {
  font-family: "IBM Plex Sans", sans-serif !important;
  font-size: 0.9rem !important;
  color: #333;
  margin-bottom: 10px;
  font-weight: 500 !important;
}

.btn-outline-secondary {
  color: #333 !important;
  border: 1px solid #333 !important;
  background-color: transparent !important;
  transition: all 0.3s ease;
  border-radius: 20px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  width: 100%;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  color: #fff;
  border-color: var(--color-secondary);
  box-shadow: 0 0 10px rgba(29, 29, 30, 0.5);
  background-color: transparent !important;
}

.btn-outline-secondary:active {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  box-shadow: none;
}

.btn:disabled {
  border-color: var(--color-secondary) !important;
  box-shadow: none;
}

.modal-footer {
  border-top: none !important;
}

[for="formTerms"] {
  font-size: 0.9rem !important;
}

.bio-content {
  font-family: "Manrope", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  background-image: url(../../images/grid4.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.language-separator {
  margin: 0 0.5rem;
}

.lawyer-detail-intro {
  background: 
    url(../../images/grid4.png) repeat center,
    linear-gradient(10deg, #efeffd 100%, #ffffff 100%);
  background-size: contain, cover;
  height: 35vh;
  margin-top: 20px;
  position: relative;
  border-bottom-left-radius: 70% 70px;
  border-bottom-right-radius: 70% 70px;
}

.lawyer-h1 {
  font-family: "Manrope", sans-serif !important;
  font-weight: 550;
}

.lawyer-h2 {
  font-family: "Manrope", sans-serif !important;
  font-weight: 350;
  font-size: 1.05rem;
}

/* Wrapper to limit width on mobile */
.ld-bio-card-wrapper {
  max-width: 100%; /* Ensure it doesn't exceed parent container width */
}

/* Base styles for all screen sizes */
.ld-bio-card {
  border: 1px solid #333 !important;
  border-radius: 20px !important;
  background-color: transparent !important;
  background-image: url(../../images/grid4.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px !important;
}

/* Inner card styling */
.ld-bio-card-inner {
  background-color: #efeffd;
  border-radius: 25px;
  height: 100%;
  width: 100%;
}

/* Adjustments for mobile screens */
@media (max-width: 767px) {
  .ld-bio-card-wrapper {
    padding-left: 15px; /* Space from the left */
    padding-right: 15px; /* Space from the right */
  }
}


.icon-style {
  font-size: 2rem;
  color: #efeffd;
  background-color: #333;
  padding: 0.3rem;
  border-radius: 50%;
}

.education-title, .language-title {
  font-family: "Manrope", sans-serif !important;
  font-size: 0.85rem !important;
  font-weight: 700;
  color: #333;
}

.education-subtitle, .language-subtitle {
  font-family: "Manrope", sans-serif !important;
  font-size: 0.9rem !important;
  font-weight: 350;
  color: #6c757d;
}

.ld-mediation {
  position: absolute;
  right: 45%;
  bottom: 15%;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  color: var(--color-primary);
  padding: 6px 18px;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  font-family: var(--secondary-font) !important;
  font-size: 0.75rem !important;
  font-weight: var(--font-weight-medium);
}

.mediation-icon {
  margin-right: 6px;
}

.lawyer-social-icons {
  position: absolute;
  right: 8%;
  bottom: 18%;
  display: flex;
  gap: 12px;
}

.social-icon {
  font-size: 1rem;
  color: #333;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--color-secondary);
}

.accordion-item .accordion-header .accordion-button {
  background-color: #f8f9fd !important;
  color: #333;
  font-weight: 550;
}

.accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background-color: #f8f9fd !important;
  color: #333 !important;
}

.accordion-item .accordion-body {
  background-color: #f8f9fd;
  color: #333;
  font-size: 0.95rem !important;
}

.accordion-item {
  border: 1px solid #333 !important; /* Set border for each accordion item */
  border-bottom: none !important; /* Remove bottom border from all items except the last */
}

.accordion-header {
  border: none !important; /* Remove border from the header */
}

.accordion-body {
  border-top: 1px solid #333 !important; /* Add a border to the top of the body */
  border-bottom: none !important; /* Ensure no bottom border overlaps */
}

.accordion-item:last-child {
  border-bottom: 1px solid #333 !important;
}

.leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.1) !important; /* Transparent background */
  backdrop-filter: blur(20px); /* Apply blur effect */
  border-radius: 10px; /* Optionally, round the corners */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for better visibility */
  font-family: "Manrope", sans-serif !important;
  font-size: 0.7rem !important;
  font-weight: 300 !important;
}

.leaflet-popup-tip {
  background-color: rgba(255, 255, 255, 0.1) !important; /* Transparent tip */
  backdrop-filter: blur(20px); /* Apply blur to the popup tip */
}

/* Flex container to align the columns */
.align-center-row {
  display: flex;
  align-items: center; /* This will center the right column vertically based on the accordion's height */
}

/* Allow the left column (with the accordion) to expand fully */
.align-center-row > .col-md-8 {
  flex: 1; /* Makes sure the left column takes up as much space as needed */
}

/* Right column remains centered based on its height */
.align-center-row > .col-md-4 {
  display: flex;
  flex-direction: column;
}