.blog-background-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45vh !important;
    background-image: url("../../images/blog.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left 50px center;
    color: #333;
  }
  
  .blog-text-container {
    text-align: right;
  }

  .blog-text-container h1{
    color: #5a39fd !important;
  }

  @media (max-width: 768px) {
    .blog-background-container {
      justify-content: center;
      background-position: center center; 
      background-size: contain;
      text-align: center;
      height: 50vh !important;
      padding-top: 30vh !important;
    }
  
    .blog-text-container h1 {
        background: rgb(255, 255, 255);
        display: inline-block;
        text-align: center !important;
      }
      
      .blog-text-container h2 {
        background: rgb(255, 255, 255) !important;
        display: inline-block;
        font-size: 0.95rem;
        text-align: center !important;
      }
  }