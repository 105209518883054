/* Add this CSS to your stylesheet */
.blur-background {
  filter: blur(5px); /* Blur the content in the background */
  pointer-events: none; /* Prevent interaction with blurred content */
}

.spinner-overlay {
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Slight opacity */
  z-index: 1000; /* High z-index to appear above everything */
}

.bottom-border-form label {
    font-weight: 600 !important;
    font-size: 0.95rem !important;
}

.bottom-border-form {
    background-color: #ffffff;
    border-radius: 30px !important;
    margin-top: 20px !important;
    padding: 25px !important;
}

.bottom-border-form-1 {
    background-color: #e5efff;
}

.bottom-border-form .form-control {
    font-size: 0.95rem !important;
    border: none !important;
    border-bottom: 1.5px solid #ccc !important;
    padding: 0 10px 15px !important;
    padding-top: 15px !important;
    line-height: 24px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    transition: border-color 0.3s ease !important; /* Smooth transition for border color */
  }
  
  .bottom-border-form .form-control:focus {
    border-bottom-color: #333 !important; /* Change bottom border color on focus */
    outline: none; /* Remove default outline */
    box-shadow: none; /* Remove focus shadow */
  }
  
  .bottom-border-form .form-check-label {
    font-weight: 400 !important;
  }

  .bottom-border-form .form-control:disabled {
    background-color: #f8f9fd;
    padding-left: 20px !important;
  }

.btn-outline-primary-bottom {
    background-color: transparent !important;
    border: 1px solid #333 !important;
}

.custom-form-group {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.custom-label {
  width: 30%;
  margin-bottom: 0px !important;
  padding: 0 0px 15px !important;
  border-bottom: 1.5px solid #ccc; 
}

.bottom-border-form .custom-input {
  width: 65% !important;
  padding: 0 25px 15px !important;
  border-bottom: 1.5px solid #ccc !important;
  transition: border-color 1.5s ease !important;
}

.custom-input:focus {
  border-bottom-color: #333 !important;
}

.custom-input:disabled {
  background-color: #f8f9fd;
}

.btn-outline-primary-bottom {
  background-color: transparent !important;
  border: 1px solid #333 !important;
}
