:root {
  --pagination-color: #333;
}

.filter-background {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1.5px solid var(--pagination-color);
  border-bottom: 1.5px solid var(--pagination-color);
  overflow: hidden;
  margin-bottom: 50px;
}

.pagination {
  margin: 0;
  padding: 10px 0;
}

.page-item {
  margin: 0 5px;
}

.page-item.active .page-link {
  background-color: var(--pagination-color);
  border-color: var(--pagination-color);
  color: #fff;
  border-radius: 25px;
}

.page-link {
  color: var(--pagination-color);
  background-color: transparent;
  border: 1px solid var(--pagination-color);
  border-radius: 25px;
  padding: 8px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-link:hover {
  background-color: rgba(51, 51, 51, 0.1);
  color: var(--pagination-color);
}

.page-item.disabled .page-link {
  color: #ccc;
  background-color: transparent;
  border-color: #ddd;
}

.lawyers-background-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50vh !important;
  background-image: url("../../images/dikigoros1.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.badge {
  font-weight: 300 !important;
}

.lawyers-background-container h1 {
  color: var(--color-secondary);
}

@media (max-width: 767px) {
  .lawyers-background-container {
    height: 35vh !important;
  }
  
}