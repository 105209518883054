.login-container {
  height: 80vh;
}

.full-height {
  height: 100%;
}

.form-container {
  max-width: 400px;
}

.left-column {
  position: relative;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem;
  background-color: #d8d0ff !important;
  background: linear-gradient(70deg, #efeffd 100%, #ffffff 100%);
  border-radius: 100%;
}

.left-column::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background-color: #d8d0ff;
  background: linear-gradient(70deg, #efeffd 100%, #ffffff 100%);
  clip-path: ellipse(30% 50% at 50% 100%);
  z-index: -1;
}

@media (max-width: 480px) {
  .left-column {
    height: 50%;
  }
  .login-container {
    height: 80vh;
  }
}

@media (max-width: 360px) {
  .left-column {
    height: 50%;
  }
  .login-container {
    height: 90vh;
  }
}
