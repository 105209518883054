footer {
    border-top: 1px solid #acacac !important;
    padding-top: 10px !important;
    margin: 10px !important;
}

footer p {
    font-size: 0.85rem !important;
}

@media (max-width: 600px) {
    footer a {
        font-size: 3vw;
    }
    
    footer p {
        font-size: 3vw !important;
    }
  }