/* Base Styles */
.navbar-brand {
  font-family: var(--brand-font);
  letter-spacing: -0.05rem;
  color: var(--color-secondary) !important;
}

.navbar-brand:hover {
  color: var(--color-secondary) !important;
}

.navbar-toggler {
  z-index: 1051;
}

.navbar-mobile-full {
  position: fixed;
  inset: 0;
  background-color: var(--color-secondary);
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transform: translateY(-30%);
  transition: opacity 0.5s ease, transform 0.8s ease;
  pointer-events: none;
}

.navbar-mobile-full.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.mobile-brand {
  position: absolute;
  font-size: 2.5vh !important;
  top: 3vh !important;
  right: 5vw !important;
}

.navbar-mobile-full .nav-link {
  color: var(--color-white) !important;
  font-size: 8vw;
  font-family: var(--primary-font);
  font-weight: var(--font-weight-regular);
  letter-spacing: -0.5px !important;
  padding-left: 10vw;
}

.navbar-mobile-full .nav-link:hover {
  color: var(--color-primary) !important;
}

.navbar-mobile-full .btn-mobile-white {
  color: var(--color-white) !important;
  border: 1.25px solid var(--color-white) !important;
  font-size: 5vw !important;
  min-width: 70vw;
  margin-bottom: 20vh;
  margin-top: 8vh;
}

.social-icons-menu {
  position: absolute;
  bottom: 5vh !important;
  right: 10vw;
  display: flex;
  gap: 1.5vw;
  opacity: 0;
}

.navbar-mobile-full.show .social-icons-menu {
  opacity: 1;
}

.social-icon-menu {
  color: var(--color-white) !important;
  font-size: 1.5vh;
  background-color: #c0b3fdfd;
  border-radius: 50%;
  padding: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon-menu:hover {
  color: #3e17fffd !important;
}

.contact-container {
  position: absolute;
  bottom: 5vh !important;
  left: 2rem;
  display: flex;
  flex-direction: column;
  z-index: -1051;
}

.nav-contact-p {
  display: flex;
  align-items: left;
  color: var(--color-white);
  font-size: 21vw !important;
  margin-bottom: 2.5vh !important;
}

.nav-contact-a {
  font-size: 2vh !important;
  color: var(--color-white) !important;
  text-decoration: underline;
  max-width: 55% !important;
}

.toggle-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='white' class='bi bi-list' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M2.5 4.5A.5.5 0 0 1 3 4h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0 4A.5.5 0 0 1 3 8h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0 4A.5.5 0 0 1 3 12h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'/%3E%3C/svg%3E");
}

.btn-outline-uplawyer {
  width: 10rem !important;
}

@media (min-width: 600px) {
  .social-icons-menu {
    display: none !important;
  }

  .nav-link:focus {
    color: #333 !important;
  }
}

@media (max-width: 900px) {
  .mobile-brand {
    top: 1.15rem !important;
    color: #5a39fd !important;
  }
}

@media (max-width: 360px) {
  .mobile-brand {
    top: 1.25rem !important;
    color: #5a39fd !important;
  }
}
/* Add transition for collapsing */
