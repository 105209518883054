.lawyer-card {
  border: 1px solid #333 !important;
  border-radius: 30px !important;
  background-color: white !important;
  background-image: url(../images/grid4.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.lawyer-profile-image-container {
  display: flex;
  justify-content: flex-start;
}

.lawyer-profile-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  filter: grayscale(100%);
}

.lawyer-card-title a {
  text-decoration: none;
  color: var(--color-primary);
  display: block;
}

.lawyer-card-subtitle {
  font-size: 0.85rem !important;
  font-weight: 400 !important;
}

.specialties-container {
  text-align: left;
  height: 100px;
  overflow-y: auto;
  justify-content: center !important;
}

.specialty-badge {
  font-size: 0.75rem !important;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 6px;
  margin-bottom: 15px;
  border: 1px solid #333;
  color: var(--color-primary);
  border-radius: 35px;
}

.contact-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  height: 80px;
  overflow-y: auto;
  justify-content: center;
}

.contact-info-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.8rem !important;
}

.single-address,
.single-phone {
  margin-bottom: 5px;
}

.card-background {
  border-radius: 25px !important;
}

.lawyer-card-grade-dikigoros_par_efetais {
  background-color: #fff6e2 !important;
}

.lawyer-card-grade-dikigoros_par_areio_pago {
  background-color: #e4dbfaee !important;
}

.lawyer-card-grade-dikigoros_para_protodikais {
  background-color: #e5efff !important;
}

.lawyer-card-icons {
  margin-right: 8px;
  margin-left: 8px;
  vertical-align: middle;
  position: relative;
}

.card-header-container {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.card-header-right {
  position: absolute;
  top: 40px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  min-width: 180px;
}

.law-mediation {
  position: absolute;
  right: 2px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  color: var(--color-primary);
  padding: 6px 18px;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.lawyer-grade {
  font-family: var(--primary-font) !important;
  font-size: 0.8rem;
  font-weight: 400;
}

.social-icons {
  display: flex;
  gap: 8px;
}

.social-icon {
  font-size: 0.9rem;
  color: var(--color-primary);
  cursor: pointer;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #007bff;
}

.law-mediation {
  font-family: var(--secondary-font) !important;
  font-size: 0.65rem !important;
  font-weight: var(--font-weight-medium);
}

.mediation-icon {
  margin-right: 5px;
}

@media (max-width: 360px) {
  .card-header-right {
    position: static;
    margin-top: 10px;
    text-align: right;
    min-width: auto;
  }

  .lawyer-grade {
    display: block;
    font-size: 0.75rem;
    margin-top: 30px;
  }

  .law-mediation {
    right: 0px;
    padding: 6px 12px;
  }

}
